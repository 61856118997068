.content-body-display {
  width: 300px;
  height: 450px;
  border-radius: 18px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1.3px rgba(151, 151, 151, 0.33);
  margin-top: 50px;
  align-items: center;
  display: flex;
  flex-flow: column;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 20px;
}

.content-body-display-text {
  width: 300px;
  height: 400px;
  border-radius: 18px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1.3px rgba(151, 151, 151, 0.33);
  margin-top: 50px;
  align-items: center;
  display: flex;
  flex-flow: column;
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 20px;
}

.body-form-created-image {
}

.img-create-image {
  width: 90%;
  height: 150px;
  object-fit: cover;
}

.row-ansenwr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.actions-card-course {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  align-items: center;
  width: 100%;
}

.img-card-course {
  height: 15px;
  width: 15px;
}
.img-card-course:hover {
  cursor: pointer;
}

.label-actions {
  font-family: Rubik;
  font-size: 14.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.label-actions:hover {
  cursor: pointer;
  opacity: 0.7;
}

.display-load {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 450px;
}
.save-label {
  font-family: Rubik;
  font-size: 18.2px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: -10px;
  color: #acacac;
}
