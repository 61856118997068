.history-label {
  height: 26px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  cursor: pointer;
}

.history-label:hover {
  cursor: pointer;
  border-bottom: 2.1px #b3247c solid !important;
}

.name-product {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.name-product-container {
  width: 100%;
  padding-left: 8px;
}

.name-product-heading {
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.body-card {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.9;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
}

.img-place-holder {
  margin-top: 50px;
  width: 400px;
  height: 300px;
  margin-bottom: 50px;
}

.labe-dialog-cofirm {
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.label-dialog-push {
  height: 14px;
  opacity: 0.41;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.item-label-product {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #403f4c;
}

.item-label-arrow {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.item-label-user {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.cancel-btn-dialog {
  width: 76px;
  height: 18px;
  font-family: Rubik;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}

.cancel-btn-dialog:hover {
  cursor: pointer;
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
}

.card-product-column {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 160px;
  padding: 0 8px;
  text-align: center;
}

.card-product-column .btn-click {
  width: 100%;
}

.card-product-column .btn-click div {
  font-size: 14px !important;
}

/* .card-product-column:first-of-type {
  max-width: 308px;
} */

.card-product-column.ico-imag {
  max-width: 16px;
}

.ico-imag:hover {
  cursor: pointer;
}

.card-product-column div {
  text-align: center;
}

.dialog-history--data {
  padding: 0 1.25rem;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-history--heading {
  margin-top: 10px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: bold;
  color: #403f4c;
}

.dialog-history--value {
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
}
