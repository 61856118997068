.container {
  font-size: 18px;
}

.imagicon:first-of-type {
  margin-left: 0;
}

.imagicon {
  margin-left: 20px;
  cursor: pointer;
}

.eye {
  opacity: 0.6;
  width: 18px;
}

.table-row:hover {
  background: #d8d8d8;
}

.tooltip {
  font-family: Rubik;
  font-size: 12.8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 5px !important;
  height: 24.9px;

  color: #000000;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
}

.tooltip-dashboard {
  font-family: Rubik;
  font-size: 12.8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 5px !important;
  color: #000000;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
}

.font-text-table {
  font-family: Rubik;
  font-size: 13.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
}

.card-item-font {
  display: inline-block;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  margin-left: 5px;
  color: #acacac;
}

.text-header-row:hover {
  cursor: pointer;
  color: #403f4c;
}

.text-header-row {
  font-family: Rubik;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.area-button {
  height: 2px;
  border: solid 1px #979797;
}

.btn-area:hover {
  opacity: 0.39;
}

.table-row {
  font-family: Rubik;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.remover-edit-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ul-pagination {
  margin-top: 2%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-pagination {
  width: 8.3px;
  height: 10.5px;
  object-fit: contain;
}

.header-label {
  font-family: Rubik;
  font-size: 18.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}

.imagicon-token {
  margin: 0;
  padding: 0;
  width: 25px;
  margin-left: 20px;
}
