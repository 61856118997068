.column-row-table {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
}

.column-row-text {
  max-width: 200px;
}
