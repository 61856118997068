.first-colum {
  display: flex;
  flex-flow: column;
}

.title-table {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.89;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}
.description-table {
  opacity: 0.5;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.header-table-names {
  opacity: 0.61;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}
.timer-row {
  display: flex;
  flex-flow: column;
}

.actions-calendar-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
