.preview-module {
  width: 327px;
  height: 450px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin-top: 0px;
  margin-left: 10px;
  position: absolute;
}
.preview-module-quiz {
  width: 220px;
  height: 500px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin-top: -175px;
  margin-left: 50px;
}

.preview-module-img {
  width: 100%;
  height: 192px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}
.preview-module-title-course {
  margin-top: 20px;
  margin-left: 10px;
  width: 90%;
  border: none;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 5px;
  color: #403f4c;
}
.tag-text-course {
  padding: 5px;
  opacity: 0.45;
  width: 60px;
  margin-top: 25px;
  border-radius: 110px;
  background-color: #d8d8d8;
  font-family: Rubik;
  font-size: 12.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0c0b0b;
}
.preview-module-title-module {
  margin-top: 20px;
  margin-left: 10px;
  width: 90%;
  border: none;
  font-family: Rubik;
  margin-left: 20px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5px;
  color: #403f4c;
}
.divisoria {
  width: 290px;
  height: 1px;

  margin-left: 20px;
  margin-right: 20px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-module-descriptio-module {
  margin-top: 20px;
  margin-left: 10px;
  width: 90%;
  opacity: 0.39;
  border: none;
  font-family: Rubik;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5px;
  color: #403f4c;
}
.preview-module-duration-course {
  margin-top: 20px;
  margin-left: 10px;
  width: 90%;
  border: none;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5px;
  color: #403f4c;
}

.preview-module-division {
  width: 327px;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-module-avatar {
  width: 17px;
  height: 17px;
  margin-top: 10px;
  background-color: #d8d8d8;
}

.preview-module-title {
  margin-top: 0px;
  margin-left: 10px;
  width: 90%;
  border: none;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 10px;
  color: #403f4c;
}

.preview-module-text {
  opacity: 0.57;
  width: 50px;
  max-width: 10px;
  font-family: Rubik;
  margin-top: 1px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  white-space: pre;
  color: #000000;
}

.option {
  height: 50px;
  width: 190px;
  margin-top: 15px;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 11px 11px 11px 11px;
  padding: 15px;
  background-color: #ffffff;
  text-align: center;
}

.img-default-module {
  height: 70px;
  margin-left: 80px;
  margin-top: 20px;
}
.img-default-simple-module {
  height: 50px;
  width: 100px;
  margin-top: 20px;
  margin-left: 60px;
  border-radius: 21px 21px 0px 0px;
}
.img-content-module {
  height: 100px;
  width: 190px;
  margin-top: 20px;
  object-fit: cover;
  border-radius: 11px 11px 11px 11px;
}
.text-area-question-module {
  margin-top: 50px;
  margin-left: 10px;
  width: 90%;
  border: none;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}
