.card-body-module {
  height: 200px;
  width: 225px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-right: 9px;
  margin-top: 19px;
  margin-bottom: 19px;
  background-color: #ffffff;
}

.row-header-module {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}
.label-name-module-card {
  width: 50%;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  margin-top: 10px;
  border-bottom: 0.5px solid #b8b8b8;
}

.label-description-module-card {
  height: 40px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  margin-top: 20px;
}

.label-content-module-card {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  margin-top: 10px;
}

.row-module-actions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 15%;
  border-top: 0.5px solid #b8b8b8;
}

.row-btns-card-modules {
  margin-top: 3%;
}
.row-btns-card-modules:hover {
  opacity: 0.7;
  cursor: pointer;
}
.label-btn-card-course {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  margin-left: 5px;
  margin-top: 1px;
}
.label-btn-card-course:hover {
  cursor: pointer;
}

.form-edit-module-course {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.btn-remove-module {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-remove-module:hover {
  cursor: pointer;
}

.header-course-module {
  margin: 0;
  padding: 0;
  height: 100px;
  display: flex;
  align-items: center;
}

.label-course-module {
  margin-right: 5px;
}
