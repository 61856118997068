.form-modality-menu {
  display: flex;
  align-items: center;
  color: #b3b3b3;
  flex: 1;
  font-size: 18px;
}

.form-modality-label-menu {
  font-weight: 700;
  font-size: 13px;
  line-height: 1.33;
  color: #b3b3b3;
  font-size: 18px;
  width: 20%;
}

.form-group-input-row-menu {
  margin-top: 12px;
}
