.publication-list-dash {
  height: 450px;
  border-radius: 4.5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  border: solid 1.5px #e6e6e6;
  width: 60%;
  margin-bottom: 50px;
}

.header-publication-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 25px;
  margin-top: 20px;
  height: 50px;
}

.main-label-header-publication-list {
  font-family: Rubik;
  font-size: 16.8px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #333333;
}

.secondary-label-header-publication-list {
  font-family: Rubik;
  font-size: 14.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #999999;
}

.header-publication-list-description {
  display: flex;
  flex-flow: column;
}

.label-title-list {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.86;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.label-title-secondary {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.label-title-secondary-action {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #acacac;
}

.label-title-secondary-action:hover {
  cursor: pointer;
  border-bottom: #acacac, solid, 1px;
}

.ico-publication-list {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-left: 10px;
}

.post-atributes-list {
  display: flex;
  align-items: center;
}

.item-list-post {
  width: 50px;
}
