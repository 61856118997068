.text-field {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}
.input:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 10px #719ece;
}
.form-create-publication {
  padding: 20px;
}
.container-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 120px;
}
.container-row-first {
  display: flex;
  align-items: flex-end;
}

.button-action-clear {
  margin-bottom: 8px;
}

.btn-click:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  font-size: 12px;
  cursor: pointer;
}
.tags {
  width: 190px;
  display: flex;
}

.tags > div > span {
  font-size: 12px !important;
}

.container-filter-type {
  margin-right: 15px;
}

.font-text-table{
  font-size: 12.7px !important;
}
