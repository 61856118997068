.create-card-course-display {
  display: flex;
  overflow-y: scroll;

  overflow-y: hidden;
  margin-top: 50px;
}

.create-card-card-default-body {
  width: 300px;
  height: 450px;
  border-radius: 18px;
  box-shadow: 0 27px 53px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1.3px rgba(151, 151, 151, 0.33);
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: center;
  margin-right: 10px;
}
.create-card-card-default-body:hover {
  cursor: pointer;
  opacity: 1;
}

.ic-adicionar {
  width: 42px;
  height: 42px;
  object-fit: contain;
  opacity: 0.5;
}

.create-card-card-default-label {
  opacity: 0.46;
  font-family: Rubik;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.create-card-card-default-label:hover {
  cursor: pointer;
}

.create-card-kinds-display {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.create-card-kinds-display-label {
  opacity: 0.46;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  width: 300px;
  margin-top: 5px;
}

.card-kinds-option {
  height: 112px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #979797;
  opacity: 0.52;
}

.card-kinds-option:hover {
  cursor: pointer;
  opacity: 1;
}
