.select-component-body-initial {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
  height: 155px;
  margin-right: 14px;
}

.select-component-body-initial:hover {
  width: 135px;
  height: 160px;
}

.label-title-component {
  font-family: Rubik;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 40%;
}

.image-title-component-initial {
  margin-top: 20px;
  margin-left: 30%;
  width: 70px;
  height: 70px;
}

.select-component-body-initial:hover {
  background-color: #ededee;
  cursor: pointer;
}

.body-select-component:hover {
  cursor: pointer;
  background-color: #ededee;
}

.label-title-component:hover {
  opacity: 0.3;
  cursor: pointer;
}

.label-add-file {
  width: 313px;
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-top: '20px';
  margin-bottom: '20px';
}

.label-component {
  margin-top: 20px;
  height: 14px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
