.nav-header-container {
  height: 100.4px;
  padding: 0 16px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
}

ul {
  list-style-type: none;
}

.ul-actions-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  padding-left: 0px;
}
.logo-menu {
  width: 100px;
  height: 100px;
}
.menu-item {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.5;
  color: #acacac;
  cursor: pointer;
}
.menu-item-select {
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
  opacity: 1;
  color: #403f4c;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
}

.menu-item:hover {
  border-bottom: #e5156b !important;
  border-bottom: 2.1px #e5156b solid !important;
  cursor: pointer;
  opacity: 1;
  height: 100%;
  color: #403f4c;
}
.ul-actions-user-config {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;
}
