.label-btn-login {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  pointer-events: auto;
  display: inline-block;
  cursor: pointer;
}

.dropDown-text-btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dropDown-text-btn img {
  width: 30px;
  height: 30px;
  padding-left: 2px;
  border-radius: 8px;
}

.dropDown-text-btn img:hover {
  box-shadow: none;
  background-color: transparent;
}

.short-answer__container {
  height: 100%;
}

/* TODO: refactor when Publication Tags feature get done */
/* span div {
  padding: 0 !important;
} */

.nohover-btnclick {
  box-shadow: none !important;
}
