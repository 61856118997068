.form-options {
  display: flex;
}

.form-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4%;
  margin-bottom: 2%;
}

.Container-header {
  display: flex;
}

.header-child {
  display: flex;
  margin-right: 105px;
  padding-bottom: 10px;
  justify-content: flex-end;
}

.enable-quiz {
  width: 100%;
  display: flex;
  align-items: center;
}

.quiz-title p {
  margin: 0;
}
