.paginationHome {
  width: 100%;
  padding-right: 39px;
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
}

.paginationClass {
  font-weight: bold;
  text-decoration: underline;
}

.paginationItem {
  margin: 0 5px;
}

.paginationLink {
  color: #acacac;
  font-size: 15px;
}

.body_container {
  margin-left: 64px;
  display: flex;
  justify-content: initial;
  align-items: initial;
}

.score,
.end-date,
.not_score_after,
.segmentation {
  display: flex;
  flex-direction: column;
  margin-right: 30px;

  font-family: 'Rubik';
  font-size: 18px;
  font-weight: 500;
  color: #403f4c;
}

.segmentation ul {
  display: flex;
  justify-content: initial;
  align-items: initial;
  flex-wrap: wrap;
  margin-left: -45px;
  max-width: 840px;
}

.segmentation ul li {
  margin-right: 10px;
}

.score > span,
.end-date > span,
.not_score_after > span,
.segmentation > span {
  font-size: 12px;
  color: rgba(80, 80, 80, 0.5);
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
}
