.product-card-body {
  width: 280px;
  cursor: pointer;
  height: 300px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 19px;
  margin-bottom: 50px;
  background-color: #ffffff;
  margin-right: 10px;
}

.card-product-header {
  display: flex;
  flex-flow: column;
}

.image-product-card {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.title-header-card-product {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
  margin-top: 19px;
  max-width: 225px;
  padding: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.description-card-product {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  max-width: 225px;
  padding: 0 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.row-product-attributes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
}

.label-score-product {
  height: 27px;
  font-family: Rubik;
  font-size: 16px;
  margin-top: 2px;
  margin-left: 5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #efc75e;
}

.row-inside-attributes {
  display: flex;
  align-items: center;
}

.amout-products {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #acacac;
}

.row-actions-card-product {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5%;
  border-top: 0.5px solid #b8b8b8;
}
