.form-group-input {
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-flow: column;
  position: relative;
  align-items: center;
}

.form-group-input-row {
  width: 100%;
  min-height: 60px;
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: space-between;
}

.form-group-input-row:last-of-type {
  justify-content: flex-end;
}

.body-form-initialo-course {
  display: flex;
  margin-top: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}

.display-form {
  width: 100%;
  display: flex;
  max-width: 510px;
  align-items: center;
  justify-content: flex-start;
}

.form-input-body {
  width: 100%;
}

.img-create-course {
  float: right;
  margin-left: 110px;
  padding-right: 0px;

  margin-left: 160px;
  height: none;
  max-height: 450px;
  max-width: 430px;
  min-width: 430px;
  min-height: 450px;
  width: none;
  object-fit: cover;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  border-radius: 15px;
}

.title-name-course {
  margin: 0;
  padding: 0;
  width: 510px;
  font-size: 24px;
  text-align: left;
  color: #333947;
  font-weight: bold;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
  margin-bottom: 24px;
}

.form-group-input-row div:nth-child(1) {
  padding-right: 10px;
}

.form-level {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.form-score-certificate {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.score-input input {
  background-color: transparent;
}

.score-input input:disabled {
  color: #b3b3b3 !important;
}

.form-has-certificate {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.form-group-input-btn {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.container-modal-patch-course {
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.header-modal-patch-course {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.body-modal-patch-course {
  width: 100%;
  height: 85%;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.body-modal-patch-course p {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  padding-left: 15px;
  line-height: 24px;
  font-family: Rubik;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  margin-bottom: 24px;
}

.btn-close-modal-patch {
  margin: 0;
  width: 100%;
  padding: 0;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: aqua;
}

.view-characters-description {
  width: 100%;
  font-size: 12px;
  text-align: left;
}

.segmentationTag-course {
  width: 100%;
}

.form-modality {
  display: flex;
  align-items: center;
  color: #b3b3b3;
  flex: 1;
}

.form-modality .form-level:first-child {
  width: auto;
  margin-right: 32px;
}

.form-modality .form-level {
  width: 66%;
}

.form-modality .form-level > div {
  width: 100% !important;
}

.form-modality-label {
  font-weight: 700;
  font-size: 13px;
  line-height: 1.33;
  color: #b3b3b3;
  width: 60%;
}

.form-modality-label--points {
  margin: 40px 0 -10px;
}

.form-label {
  padding: 0 7px;
  cursor: pointer;
}
