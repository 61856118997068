.preview-publication {
  width: 327px;
  height: 350px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
}

.preview-publication-img {
  width: 327px;
  height: 170px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}

.preview-publication-video {
  object-fit: cover;
  border-radius: 11px 11px 0px 0px;
}
.preview-publication-externo {
  border: none;
  border-radius: 11px 11px 0px 0px;
  width: 327px;
  height: 170px;
}
.preview-corse-img {
  width: 327px;
  height: 140px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}
.preview-corse-img-preview {
  width: 170px;
  height: 150px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
  margin-left: 25%;
}
.img-default {
}
.text-area-preview {
  border: none;
  width: 98%;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  height: 450px;
  background-color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
  overflow: auto;
}

.text-area-preview-course {
  border: none;
  width: 100%;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  background-color: #ffffff;
  margin-top: 10px;

  overflow: auto;
}
.preview-publication-division {
  width: 327px;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-publication-avatar {
  width: 17px;
  height: 17px;
  margin-top: 10px;
  background-color: #d8d8d8;
}

.preview-publication-user {
  margin-top: 25px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  align-content: 'left';
}

.preview-publication-title {
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  margin-top: 15px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.preview-publication-text {
  opacity: 0.57;
  font-family: Rubik;
  margin-top: 10px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.tag-text {
  opacity: 0.45;
  border-radius: 110px;
  background-color: #d8d8d8;
  font-family: Rubik;
  font-size: 12.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c0b0b;
}

.est-curso-desc {
  display: flex;
  margin-top: 20px;
  list-style-type: none;
  align-items: center;
  padding: 0;
}

li {
  margin-left: 5px;
  margin-right: 5px;
}

.text-duration {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
}
