.body-modal {
  border-radius: 14px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
  border: solid 1.2px #dcdcdc;
}

.modal-header {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-flow: column;
}

.main-label-import {
  font-family: Rubik;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}
.secondary-label-import {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  margin-bottom: 2%;
}

.modal-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 15%;
  border-bottom: solid 1px rgba(153, 153, 153, 0.62);
}

.container-header {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 15%;
}

.text-label-description {
  font-size: 10px;
  color: #999999;
  text-align: center;
}

.img-header-import {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-left: 2%;
  padding-right: 2%;
}

.img-header-import img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.description-body-text {
  display: flex;
  height: 58vh;
  overflow: scroll;
  justify-content: left;
  flex-flow: column;
  margin-top: 0;
  padding-right: 2%;
  padding-left: 2%;
}

.title-label {
  font-family: Rubik;
  font-size: 16px;
  margin-top: 10px;
  color: #333333;
  font-weight: 600;
}

.text-label-title {
  font-size: 18px;
  text-align: left;
  margin-bottom: 0;
  color: #3b3b3b;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.list-label {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 1%;
  padding-left: 0;
}

.label-text-strong {
  font-family: Rubik;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-top: 3px;
  color: #3b3b3b;
  cursor: pointer;
}

.drop-zone-link {
  display: flex;
  padding-top: 2px;
  align-items: center;
  justify-items: center;
  text-align: center;
  width: 170px;
  height: 25px;
  cursor: pointer;
}

a:link {
  text-decoration: none;
}

.text-label {
  font-size: 16px;
  color: #999999;
  margin: 5px 0px 5px;
}

.text-label-regular {
  font-size: 16px;
  color: #3b3b3b;
  margin: 5px 0px 5px;
}

.secondary-label-list-down {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  margin-bottom: 2%;
  margin-top: 2%;
  padding-left: 0;
}
.secondary-label-list-down:hover {
  cursor: pointer;
  border-bottom: #999999;
}

.modal-actions-import {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 30px 2% 30px;
}

.text-title-color {
  font-family: Rubik;
  color: #b3247c;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  background-color: transparent;
}

.drop-zone-input {
  border-radius: 7px;
  align-items: center;
  width: 200px;
  height: 40px;
  background-color: #ffffff;
  border: solid 1px #979797;
  background-color: #ffffff;
}
.label-drop-zone {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 5%;
}

.drag-area {
  height: 100%;
}

.container-drop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
}
