.container-segmentation-tag {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
}

.header-segmentation-tag {
  width: 100%;
  height: 30px;
  display: flex;
  text-align: center;
  align-items: center;
  margin: 16px 0px 10px;
  justify-content: center;
}

.header-segmentation-tag h2 {
  margin: 0;
  padding: 0;
  height: 20px;
  display: flex;
  font-size: 18px;
  color: #3b3b3b;
  font-style: normal;
  text-align: center;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.segmentation-btn {
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  font-family: Rubik;

  border: none;
  color: white;
  cursor: pointer;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.segmentation-btn:focus {
  outline: 3px #eee;
}

.container-selection-tags {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;
  align-items: center;
  justify-content: flex-start;
}

.selection-tags-canvas-publish {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.add-tag-btn-segmentation {
  padding: 0;
  width: 100px;
  height: 26px;
  margin: 0px 8px 8px;

  border: none;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.5s;
}

.add-tag-btn-segmentation:hover {
  opacity: 0.8;
}

.view-segmentation-tag {
  width: 100%;
  display: flex;
  align-items: center;
}

.segmentation-tag-content {
  display: flex;
  align-items: center;
}

.segmentation-tag-content img {
  width: auto;
  height: 100%;
  margin: 0px 5px;
  border-radius: 8px;
}

.segmentation-tag-content img:hover {
  cursor: default;
  box-shadow: none;
  border-radius: 8px;
  background-color: transparent;
}

.removed-tag-and-expression {
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.remove-tag-btn-segmentation {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;

  border: none;
  cursor: pointer;
  margin-bottom: 8px;
  padding-bottom: 2px;
  line-height: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
  background-color: transparent;
  border-bottom: 1px solid #d8d8d8;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.5s;
}

.remove-tag-btn-segmentation:hover {
  background-color: #eee;
}
