.title-canvas {
  margin-top: 30px;
  height: 36px;
  font-family: Rubik;
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.form-edit-canvas {
  display: flex;
}
.body-canvas-edit {
  margin-bottom: 5%;
}

.button-schedule {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 20px;
  margin-right: 9%;
}

.container-modal-schedule {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row-exit-schedule {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0 0;
}

.label-title {
  margin: 0 auto;
  font-family: Rubik;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
}

.body-schedule {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.body-schedule-confirm {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 80px 0 20px;
}

.label-form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.label-chindren-date {
  text-align: initial;
}

.label-chindren {
  margin-top: 20px;
  text-align: initial;
}

.label-chindren-button {
  margin: 25px 0 20px;
  align-items: center;
}

.label-confirm-button {
  margin: 50px 0 20px;
  align-items: center;
}

.form-choice {
  width: 100%;
  margin: 40px 20px 20px;
}

.body-first-question {
  display: flex;
  flex-direction: column;
}

.form-questions {
  display: flex;
  flex-direction: column;
}

.form-title-preview {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

.container-draw {
  padding-top: 20px;
}

.ck-content ul {
  list-style-type: disc !important;
}

.ck-content li {
  font-size: 1.5rem;
}

.text-red {
  color: red;
}
