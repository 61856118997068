.card-details-body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 18px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1.3px #e6e6e6;
  margin: 20px 20rem 20px;
}

.header-title-course {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-details-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
}

.card-detail-label-title {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 10px;
}

.card-detail-label-tag {
  opacity: 0.65;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.card-detail-label-score {
  font-family: Rubik;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffb200;
  margin-left: 20px;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #ffb200;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.card-detail-img {
  float: right;
  margin-left: 50px;
  padding-right: 0px;
  border-radius: 0px 16px 16px 0px;
  height: 120px;
  max-height: 118px;
  max-width: 300px;
  width: none;
  object-fit: cover;
}

.card-details-display-column {
  display: flex;
  flex-flow: column;
  margin-left: 10px;
}

.list-cards {
  display: flex;
  overflow-y: scroll;
  overflow-y: hidden;
}

.module-row {
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-left: 15px;
}

.label-module-row {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #404040;
}
.label-module-row-cards {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #666666;
  margin-left: 20px;
}

.header-details {
  align-self: flex-start;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title-header-details {
  align-self: flex-start;
  font-family: Rubik;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  padding: 10px 0px 10px 20px;
}

#subheader-buttons {
  display: flex;
  margin-left: auto;
  margin-right: 20px;
}

.numbers-subtitle {
  font-size: 16px;
  text-align: left;
  color: #403f4c;
  cursor: pointer;
  margin-left: 24px;
}

.body-details {
  width: 100%;
}

.body-details-first {
  display: flex;
  padding: 0px 0px 10px 10px;
}

.menu-item-details {
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  opacity: 0.5;
  color: #403f4c;
  cursor: pointer;
}

.menu-item-details:hover {
  border-bottom: 2.1px #e5156b solid;
  cursor: pointer;
  opacity: 1;
  color: #403f4c;
  height: 100%;
}

.active {
  border-bottom: 2.1px #e5156b solid;
  opacity: 1;
}

.send-invitation-course {
  margin-right: 20px;
}

.btn-send-msg {
  width: 185px;
  height: 30px;
  border: none;
  font-size: 14px;
  color: #501f99;
  line-height: 20px;
  padding-left: 35px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background-position: left;
  background-repeat: no-repeat;
  background-color: transparent;
  background-image: url('../course/img/icon-msg.svg');
}

.btn-send-msg p {
  margin: 0;
  padding: 0;
}

.btn-send-msg:hover p {
  border-bottom: 1px #501f99 solid;
}

.send-notification-course {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: column;
}

.notification-title {
  margin: 0;
  padding: 0;
  font-size: 28px;
  color: #2f313d;
  font-weight: 600;
  margin-top: 10px;
  line-height: 40px;
  font-style: normal;
  font-family: Poppins;
}

.notificaiton-msg-description {
  font-size: 12px;
  color: #8c91a9;
  line-height: 16px;
  font-style: normal;
  font-family: Rubik;
  font-weight: normal;
  text-transform: uppercase;
}

.notification-body-msg {
  width: 510px;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid #ccd2e0;
}

.msg-notification-course-input {
  outline: 0;
  width: 100%;
  height: 40px;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 300;
  color: #2f313d;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
  background-color: #f5f7fa !important;
  border-radius: 8px 8px 0px 0px;
}

.msg-notification-course-input::placeholder {
  font-size: 16px;
  font-weight: 300;
  color: #ccd2e0;
  line-height: 24px;
  font-style: normal;
  font-family: Rubik;
}

.notification-img-close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.notification-btn-msg {
  width: 100%;
  display: flex;
  padding: 50px 0px;
  align-items: center;
  justify-content: center;
}
