.label-dialog-main {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a6a6a6;
  width: 70%;
  margin-left: 1%;
}

.label-dialog-main-select {
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.label-dialog-main-select:hover {
  cursor: pointer;
  border-bottom: 1px solid #3b3b3b;
}

.label-carousel {
  opacity: 0.45;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.label-dialog-main-select:hover {
  cursor: pointer;
  opacity: 1;
}

.exit-dialog {
  font-size: 36px;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
  background-color: #ffffff;
}
.exit-dialog-text {
  font-size: 36px;
  cursor: pointer;
}

.exit-dialog-text:hover {
  cursor: pointer;
  opacity: 0.5;
}

.body-dialog-row {
  height: 7px;
  border-top: 5px solid #ffff;
  border-radius: 5px 3px 3px 10px;
}
.body-dialog-row:hover {
  cursor: pointer;
}

.dropzone-class:hover {
  cursor: pointer;
}
.img-button-click {
  width: 50px;
  height: 50px;
  background-color: #ffffff;
  border: none;
  margin-right: 3%;
}
.img-button-click:hover {
  cursor: pointer;
  opacity: 0.5;
}

.erro-images {
  text-align: center;
  opacity: 0.45;
  font-family: Rubik;
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 30px;
}

.dropzone-class {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: none;
}

.dropzone-class-border {
  border: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  border: none;
}

.body-row-exit {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
.body-row-exit:hover {
  cursor: pointer;
  opacity: 0.3;
}

.form-link-class {
  display: flex;
  flex-flow: column nowrap;
  padding-left: 3%;
}

.form-link-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: -10%;
  margin-right: 3%;
}

.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.modal_title {
  font-size: 22px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4e5c;
  margin-bottom: 16px;
}

.modal_container_select {
  display: flex;
  gap: 25px;
}

.modal_select {
  display: flex;
  align-items: center;
  gap: 5px;
}

.modal_select_label {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4e5c;
}

.modal_link_input {
  width: 98%;
  height: 48px;
  border-radius: 4px;
  background: #e7ebf4;
  border: none;
  color: #2e63fb;
  padding: 5px 5px 5px 15px;

  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: start;
}

.modal_link_input::placeholder {
  color: #2e63fb;
}

.modal_link_input:focus {
  outline: none;
}

.modal_container_buttons {
  display: flex;
  gap: 25px;
}

.dropzone_container {
  max-width: 400px;
  max-height: 100px;
  width: 100%;
}

.dropzone_container:hover {
  cursor: pointer;
}

.dropzone_content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none;
  background: #e7ebf4;
  height: 100px;
}

.dropzone_container .dropzone_content .dropzone_text {
  margin: 0;
  color: #2e63fb;
}

.dropzone_container .dropzone_content .dropzone_text .dropzone_mark_text {
  text-decoration: underline;
  color: #2e63fb;
}
