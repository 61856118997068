.body-event-row {
  display: flex;
  flex-flow: column;
  margin: 5px;
  overflow-y: scroll;
}

.title-event-row {
  font-family: Rubik;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}
.description-event-row {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
  max-height: 170px;
}

.tag-event-row {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}

.tag-event-row-content {
  display: flex;
  flex-flow: row;
}
.divisori-event-row {
  width: 100%;
  border: solid 1px #e2e2e2;
  margin-top: 10%;
  margin-bottom: 5%;
  height: 1px;
  opacity: 0.7;
}
.body-event {
  overflow: scroll;
}
.header-row-event {
  display: flex;
}

.img-remove-event {
  margin-right: 4%;
}
.img-remove-event:hover {
  cursor: pointer;
}

.img-edit:hover {
  cursor: pointer;
}
.row-header-event {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.body-event-img {
  display: flex;
  justify-content: space-around;
  width: 20%;
}
