.form-createoredit {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60%;
}

.icon-active svg path {
  fill: 'red';
}

.modal_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.modal_title {
  font-size: 22px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4e5c;
  margin-bottom: 16px;
}

.modal_container_select {
  display: flex;
  gap: 25px;
}

.modal_select {
  display: flex;
  align-items: center;
  gap: 5px;
}

.modal_select_label {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4e5c;
}

.modal_link_input {
  width: 98%;
  height: 48px;
  border-radius: 4px;
  background: #e7ebf4;
  border: none;
  color: #2e63fb;
  padding: 5px 5px 5px 15px;

  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: start;
}

.modal_link_input::placeholder {
  color: #2e63fb;
}

.modal_link_input:focus {
  outline: none;
}

.modal_container_buttons {
  display: flex;
  gap: 25px;
}
