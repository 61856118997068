.header-historical {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-child-title {
  width: 100%;
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title-history {
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-historical {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.title-historical p {
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  text-align: left;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.filter-historical {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.body-historical {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid #ececec;
}

.content-list-history {
  width: 100%;
  height: 50px;
  font-size: 13px;
  color: #000000;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.content-list-history img {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 1.5rem;
}

.body-details-first-historical {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.header-section-list {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  justify-content: space-between;
}

.header-full-score {
  height: 4rem;
  display: flex;
  padding: 0 2.5rem;
  align-items: center;
  justify-content: flex-end;
  border-radius: 4px 0px 0px 4px;
  flex-grow: 1;
  gap: 16px;
}

.header-full-score svg {
  cursor: pointer;
  margin-right: 8px;
}

.header-full-score p {
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 16px;
  color: #2f313d;
  font-weight: 600;
  line-height: 41px;
  font-style: normal;
  font-family: Rubik;
}

.header-full-score p:nth-child(3) {
  color: #efc75e;
}

.header-full-score img {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
}

.text-color-historical {
  width: 50%;
  display: flex;
  color: #efc75e;
  align-items: center;
  justify-content: space-between;
}

.text-edit-historical-img {
  justify-content: flex-end;
}

.text-color-historical img {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.text-color-historical img:nth-child(1) {
  cursor: pointer;
}

#img-history-publish {
  margin: 0;
  padding: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 8px;
  margin-right: 16px;
}

.filter-search {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-historical-date {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

.header-title-user-history {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.title-name-user-history {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 16px;
  color: #2f313d;
  font-weight: 500;
  line-height: 19px;
  font-style: normal;
  font-family: Rubik;
}

.img-user-history {
  margin: 0;
  padding: 0;
  width: 4rem;
  height: 4rem;
  margin-left: 5px;
  object-fit: cover;
  margin-right: 16px;
  border-radius: 100%;
  border: 2px solid rgba(125, 134, 161, 0.5);
}

.img-user-history:hover {
  cursor: default;
  border-radius: 100%;
}

.content-user-details-history {
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #2f313d;
  line-height: 19px;
  font-style: normal;
  align-items: center;
  font-family: Rubik;
}

.content-user-details-history img {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.content-user-details-history svg {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

#img-publish-details-history {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 1.5rem;
}

.text-color-title-publish {
  color: #efc75e;
}

.content-publish-point-reason {
  display: flex;
  align-items: center;
}

.content-publish-point-reason p {
  margin: 0;
  padding: 0;
  margin-left: 10px;
}

.download-report-btn {
  height: 40px;
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  cursor: pointer;
  font-size: 15px;
  transition: 0.15s all;
}
.download-report-btn:hover {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
}
.download-report-btn:active {
  box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.11);
  filter: brightness(1.15);
}

.download-report-btn-txt {
  display: inline-block;
  width: 150px;
  height: 18px;
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  vertical-align: text-top;
  color: rgb(255, 255, 255);
}
