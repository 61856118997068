.corse-card-body {
  width: 300px;
  cursor: pointer;
  height: 300px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 19px;
  margin-bottom: 19px;
  background-color: #ffffff;
  margin-right: 10px;
}
.corse-card-body:hover {
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.14),
    0 20px 40px 0 rgba(0, 0, 0, 0.1);
}
.card-body-disable:hover {
  box-shadow: 0 24px 48px 0 rgba(0, 0, 0, 0.14),
    0 20px 40px 0 rgba(0, 0, 0, 0.1);
}
.card-body-disable {
  width: 300px;
  cursor: pointer;
  height: 300px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 19px;
  margin-bottom: 19px;
  background-color: #ffffff;
  margin-right: 10px;
}
.cardbodyitem {
  background: #ffffff;
  height: 50px;
  width: 200px;
}

.title-item {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #403f4c;
  margin-top: 5px;
  margin-left: 2%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.descritionitem {
  height: 32px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  color: #acacac;
  margin-top: 5px;
  text-align: center;
}
.textbtnicon {
  display: inline-block;
  margin-left: 8px;
}
.btnremove {
  height: 14px;
  border: none;
  background: #ffffff;
  margin-left: 20px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-top: '2px';
  text-align: center;
  color: #acacac;
  display: inline-block;
  padding: 5px;
  opacity: 1;
}

.score-item-course {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #efc75e;
  margin-left: 5px;
}

.score-item-name {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #efc75e;
  margin-left: 5px;
}

.itemquant {
  width: 55.7px;
  height: 14px;
  font-family: Rubik;
  font-size: 10px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;

  text-align: right;
  color: #acacac;
  margin-top: 20px;
  margin-left: 30px;
}

.btnarea {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.card-division-course {
  width: none;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}
.btnitem {
  width: 50%;
  height: 100%;
  border: none;
  background: #ffffff;
  margin-right: 0px;
  margin-top: 30px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-top: '2px';
  text-align: center;
  color: #acacac;
  display: inline-block;
  padding: 5px;
}

.labelbtn {
  width: 43.6px;
  height: 14px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: #acacac;
}

.image-course {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}
.span {
  position: absolute;
  top: 90px;
  margin-left: -175px;
}
.duration-item {
  height: 12px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-align: left;
  color: #acacac;
  margin-left: 5px;
}

.moduleitem {
  opacity: 0.75;
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #403f4c;
  margin-left: 10px;
}
.moduleitemtopic {
  opacity: 0.75;
  font-family: Rubik;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #403f4c;
  margin-left: 15%;
}

.row-course-info {
  display: flex;
  flex-flow: column;
  margin-top: 2%;
}
.row-course-attribute {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
}
.img-score {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.items-description {
  display: flex;
}
.row-enable-course {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
}

.img-duration {
  width: 20px;
  height: 20px;
  margin-top: 0px;
}

.toogle-course {
  background-color: white;
  width: 50px;
  z-index: 20;
  margin-bottom: -30px;
  margin-left: 5px, 50px;
  border-radius: 10px 5px 0px 5px;
  background-color: #ffffff;
  padding: 5px;
}

.delete-course {
  background-color: white;
  width: 50px;
  z-index: 20;
  margin-bottom: -30px;
  margin-left: 5px, 50px;
  border-radius: 0px 5px 10px 5px;
  background-color: #ffffff;
  padding: 5px;
}

.toogle-row {
  display: flex;
  justify-content: flex-end;
}

.delete-row {
  display: flex;
  justify-content: flex-start;
}
