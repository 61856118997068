.row-header-term {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2%;
}

.term-header {
  font-family: Rubik;
  font-size: 28.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}
