.header-content-text-segmentation {
  font-size: 22px;
  font-weight: 600;
  color: #2f313d;
  line-height: 32px;
  font-style: normal;
  text-align: center;
  font-family: Rubik;
  margin: 10px 0px 5px 0px;
}

.footer-segmentation-content {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-segmentation-content img {
  margin: 0px 5px;
  border-radius: 8px;
}

.footer-segmentation-content img:hover {
  cursor: default;
  box-shadow: none;
  border-radius: 8px;
  background-color: transparent;
}

.tag-segmentation-content-modal {
  width: auto;
  height: auto;
  display: flex;
  color: white;
  font-size: 13px;
  padding: 2px 8px;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.content-segmentation-tag {
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 25px 0px;
  border-bottom: 0.5px solid #ccd2e0;
}

.last-footer-segmentation {
  border: none;
}

.content-segmentation-tag h4 {
  font-size: 22px;
  font-weight: 600;
  color: #2f313d;
  line-height: 32px;
  font-style: normal;
  font-family: Rubik;
}

.content-segmentation-tag p {
  padding: 0;
  margin-bottom: 16px;
}

.container-illustration-segmentation-tag {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 8px;
  align-items: center;
  background: #f5f7fa;
  justify-content: space-evenly;
}

.circle-segmentation {
  width: 88px;
  height: 88px;
  display: flex;
  font-size: 14px;
  color: #2f313d;
  text-align: center;
  align-items: center;
  border-radius: 100%;
  mix-blend-mode: normal;
  justify-content: center;
  background-color: rgba(204, 210, 224, 0.35);
}

.segmentation-tag-intercession {
  justify-content: center;
}

.segmentation-tag-intercession .circle-segmentation {
  width: 96px;
  height: 96px;
}

.segmentation-tag-intercession .circle-segmentation:nth-child(1) {
  margin-right: -10px;
}

.segmentation-tag-intercession .circle-segmentation:nth-child(2) {
  margin-left: -10px;
}

.segmentation-tag-subtraction {
  justify-content: center;
}

.segmentation-tag-subtraction .circle-segmentation {
  width: 113px;
  height: 113px;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.segmentation-tag-subtraction .circle-segmentation div {
  width: 40px;
  height: 40px;
  margin: 30px 30px 0px 0px;
  background-color: rgba(204, 210, 224, 1);
}
