.ul-sub-nav {
  list-style-type: none;
  display: flex;
  align-items: center;
  width: 50%;
}

.ul-sub-nav-actions {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
}

.wrap-list {
  display: flex;
}

.menu-item-secondary {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
  height: 100%;
}
.menu-item-secondary:hover {
  opacity: 0.3;
  cursor: pointer;
  border-bottom: 1px solid #403f4c;
}

.header-second-border {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.notification-container {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  user-select: none;
}

.notification-container label {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(125, 134, 161, 1);
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
}
.notification-container input {
  visibility: hidden;
}

.checkmark {
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 2px;
  position: relative;
}

.notification-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.notification-container input:checked ~ .checkmark {
  background-color: #3477eb;
}

.notification-container .checkmark:after {
  content: '';
  display: none;
  position: absolute;
  left: 7px;
  bottom: 6px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.notification-container input:checked ~ .checkmark:after {
  display: block;
}

.ul-sub-nav span {
  font-size: 12px;
  color: rgba(80, 80, 80, 0.5);
  font-family: 'Rubik', sans-serif;
  font-weight: 300;
  margin-left: 20px;
}
