.header-comments {
  border-top: 1px, solid, #e5156b;
  height: 5px;
  background-color: #e5156b;
  border-radius: 5px 5px 0 0;
}

.header-row-comments {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1.5px #cccccc;
  height: 15%;
}

.header-row-exit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-row-report-header:hover {
  cursor: pointer;
}

.header-row-exit:hover {
  cursor: pointer;
}

.label-comments {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  margin-top: 1%;
}

.img-exit {
  width: 5px;
  height: 5px;
  object-fit: contain;
}

.body-comments {
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  height: 80%;
}

.row-comments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 5%;
  margin-left: 5%;
}

.user-name-comments {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
}

.comments-timer {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #999999;
}

.col-comments {
}

.comment-area {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: flex-start;
}

.comments-text {
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  margin-top: 5px;
}

.remover-comment-label {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.remover-comment-label:hover {
  cursor: pointer;
  text-decoration: underline;
}

.col-row-comments {
  width: 70%;
  display: flex;
  flex-flow: column;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.field-search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.remover-comment-label-confirm {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.form-report {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 500px;
  padding: 20px;
}

.header-row-report {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.label-desc-report {
  width: 400px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #666666;
}

.display-flex-toogle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  text-align: left;
  opacity: 0.53;
}
