.preview-product {
  width: 350px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  padding-bottom: 32px;
}
.preview-product:hover {
  cursor: pointer;
}
.preview-product-img {
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}
.preview-product-video {
  width: 220px;
  height: 150px;
  border-radius: 21px 21px 0px 0px;
}
.img-default-product {
  width: 170px;
  height: 150px;
  margin-left: 24%;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}
.img-default-product-drop {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 21px 21px 0px 0px;
}

.preview-product-division {
  width: 100%;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-product-avatar {
  width: 17px;
  height: 17px;
  margin-top: 10px;
  background-color: #d8d8d8;
}

.preview-product-user {
  margin-top: 25px;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #acacac;
  align-content: 'left';
}

.preview-product-title {
  max-width: 350px;
  font-family: Rubik;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  word-break: break-all;
  margin-top: 15px;
  margin-left: 20px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.preview-product-text {
  opacity: 0.57;
  font-family: Rubik;
  margin-top: 10px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  margin-left: 20px;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.tag-text {
  padding: 10px;
  opacity: 0.45;
  border-radius: 110px;
  background-color: #d8d8d8;
  margin-top: 3px;
  font-family: Rubik;
  font-size: 12.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c0b0b;
}

.row-score-text {
  height: 27px;
  display: flex;
  margin-top: 15px;
  margin-left: 30px;
  text-align: center;
  align-items: center;
}

.text-score {
  margin: 0;
  height: 100%;
  font-size: 16px;
  color: #efc75e;
  padding-top: 1px;
  font-weight: 600;
  padding-left: 5px;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-family: Rubik;
  letter-spacing: normal;
}

.text-product {
  margin-top: 10px;
  height: 14px;
  margin-left: 12px;
  margin-right: 10px;
  font-family: Rubik;

  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #acacac;
}

.text-product .type-description {
  color: #acacac;
  font-size: 14px;
  opacity: 0.66;
}

.img-product {
  height: 14px;
  margin-left: 12px;
  margin-right: 8px;
}

.text-area-preview-product {
  border: none;
  width: 300px;
  height: 70px;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.preview-product-row {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
