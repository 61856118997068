.table-select {
  border-radius: 5.3px !important;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
  margin-top: 2%;
  padding-left: 20px;
}

.th-head {
  height: 70px;
  border-bottom: 1px solid #eeeeee;
}

.th-head .main-checkbox > div {
  margin-left: 69px !important;
}

.labels-headers {
  opacity: 0.61;
  font-family: Rubik;
  font-size: 12.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 8px;
  padding-left: 15px;
}
.labels-headers:last-of-type .select-item-header {
  padding-left: 0;
  justify-content: center;
}

.labels-headers:hover {
  cursor: auto !important;
  color: #333333;
}
.labels-headers-marked {
  font-family: Rubik;
  font-size: 18.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 8px;
}
.labels-headers-marked:hover {
  cursor: pointer;
  color: #333333;
}

.table-how-selectable {
  height: 70.8px;
  border-radius: 5.3px;
  background-color: #ffffff;
}
.table-how-selectable:hover {
  border: solid 1.3px rgba(11, 11, 11, 0.68);
  cursor: pointer;
}

.table-body-selectable .table-how-selectable {
  display: flex;
  align-items: center;
}

.ic-order {
  width: 8px;
  height: 8px;
  margin-left: 8px;
}

.select-item-header {
  display: flex;
  align-items: center;
  text-align: center;
}

.check-and-photo-area {
  display: flex;
  align-items: center;
}

.checkBox {
  width: 21px;
  height: 21px;
  border-radius: 3px;
  border: solid 1.5px #979797;
  background-color: #ffffff !important;
  color: #ffffff !important;
}

.main-items {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.secondary-items {
  align-items: center;
}

.th-row {
  align-items: center;
  height: 60px;
  border-radius: 5px !important;
}
.th-row:hover {
  background-color: #f8f8f8;
}

.table th,
.table td {
  vertical-align: middle !important;
  border-top: none;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: middle !important;
  border-top: none;
}

td {
  font-family: Rubik;
  font-size: 13.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
}

.email {
  display: flex;
  flex-direction: column;
  opacity: 0.5;
}

.th-heade {
  height: 50px;
  border: solid;
}

.teste {
  border-radius: 5px;
}
