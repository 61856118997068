.preview-content {
  width: 220px;
  height: 350px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  margin-top: -10%;
  margin-left: 50px;
}
.preview-content-quiz {
  width: 220px;
  height: 450px;
  border-radius: 21px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin-top: -70px;
  margin-left: 70px;
  position: absolute;
}

.preview-content-img {
  width: 100%;
  height: 90px;
  object-fit: cover;
  border-radius: 21px 21px 21px 21px;
}

.preview-content-division {
  width: 327px;
  height: 8px;
  background-color: rgba(64, 63, 76, 0.3);
}

.preview-content-avatar {
  width: 17px;
  height: 17px;
  margin-top: 10px;
  background-color: #d8d8d8;
}

.preview-content-title {
  margin-top: 50px;
  margin-left: 10px;
  width: 90%;
  border: none;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 20px;
  color: #403f4c;
}

.preview-content-text {
  opacity: 0.57;
  width: 50px;
  max-width: 10px;
  font-family: Rubik;
  margin-top: 1px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  white-space: pre;
  color: #000000;
}

.option {
  height: 50px;
  width: 190px;
  margin-top: 15px;
  box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 11px 11px 11px 11px;
  padding: 15px;
  background-color: #ffffff;
  text-align: center;
}

.img-default-content {
  height: 100px;
  margin-left: 40px;
  margin-top: 20px;
}
.img-default-simple {
  height: 50px;
  width: 100px;
  margin-top: 20px;
  margin-left: 60px;
  border-radius: 21px 21px 0px 0px;
}
.img-default-quiz {
  margin-left: 70px;
  margin-top: 30px;
}
.img-content {
  height: 100px;
  width: 190px;
  margin-top: 20px;
  object-fit: cover;
  border-radius: 11px 11px 11px 11px;
}
.text-area-question {
  margin-top: 50px;
  margin-left: 10px;
  width: 90%;
  border: none;
  opacity: 0.39;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #403f4c;
}
