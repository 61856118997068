.label-tags {
  height: 14px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #616161;
}
.label-email {
  height: 12px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: left;
  color: #616161;
}

.row-speaker-colum {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}

.auto-complete-tags-user .error-label-input {
  bottom: 90px;
}
