.card-details-module {
  margin-top: 10px;
  height: 100px;
  background-color: #ffffff;
  padding: 10px;
  padding-left: 20px;
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.module-title {
  font-family: Rubik;
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}
.module-description {
  opacity: 0.26;
  font-family: Rubik;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.score-text {
  height: 35px;
  font-family: Rubik;
  font-size: 25.3px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #efc75e;
}
.card-content-hide {
  background-color: #ffffff;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
  height: 100%;
}

.text-card-content {
  font-family: Rubik;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #403f4c;
}

.ico-drop {
  margin-top: -10px;
  width: 30px;
  padding: 10px;
  border-radius: 16px;
}
.ico-remove {
  width: 33px;
  height: 33px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
}
.ico-content {
  width: 88px;
  height: 23px;
  margin-top: 7px;
  border-radius: 14px;
  opacity: 0.41;
  border: solid 1px #403f4c;
  opacity: 0.41;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 3px;
}
