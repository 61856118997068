.body-form {
  opacity: 0.9;
  padding: 20px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.body-form-submit {
  width: 70%;
  display: flex;
  padding: 20px;
  margin-top: 10px;
  min-height: 220px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.label-canvas {
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-bottom: 10px;
  margin-top: 10%;
}

.label-canvas-secondary {
  height: 18px;
  font-family: Rubik;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-bottom: 10px;
  margin-top: 16px;
}

.img-next {
  position: absolute;
  margin-top: 40%;
  margin-left: 0px;
  background-color: #ffffff;
  border: none;
  border-color: #ffffff;
  height: 27px;
  width: 14px;
}

.img-next:hover {
  cursor: pointer;
}

.label-canvas-bottom {
  height: 14px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-top: 20px;
}

.img-next-btn {
  width: 5%;
  right: 5%;
  position: absolute;
}

.form-date-cover {
  width: 80%;
  display: flex;
}

.img-create {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pontuation-form {
  position: relative;
  width: 300px;
  margin: 0 10px 0 0;
}

.cancel {
  position: absolute;
  width: 10px;
  height: 10px;
}

.cancel > img {
  position: relative;
  margin-left: 262px;
  margin-top: 42px;
  cursor: pointer;
}

.form-choice-send {
  margin: 0;
  margin-left: -35px;
  padding: 0;
  width: 300px;
  height: 100px;
}

.cover-img-publish {
  /* width: 200px; */
  height: auto;
}

.cover-img-publish-create-post .preview-cover-movie {
  height: 100% !important;
}

.cover-img-publish-create-post,
.video-react-control-bar {
  top: 240%;
}

span.note-schedule {
  height: 14px;
  opacity: 0.53;
  font-family: Rubik;
  font-size: 12px;
  text-align: center;
  color: #3b3b3b;
  margin-top: 20px;
}

.details-publish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .col-md-6{
  background-color: red;
} */
