.header-row-views {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1.5px #cccccc;
  height: 15%;
}

.header-row-exit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-row-report-header:hover {
  cursor: pointer;
}

.header-row-exit:hover {
  cursor: pointer;
}

.label-views {
  font-family: Rubik;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #333333;
  margin-top: 1%;
}

.img-exit {
  width: 5px;
  height: 5px;
  object-fit: contain;
}

.body-views {
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  height: 80%;
}

.row-views {
  display: flex;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 5%;
  margin-left: 5%;

  gap: 22px;
}

.user-name-views {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d2d2d;
}

.col-views {
}

.col-row-views {
  width: 70%;
  display: flex;
  flex-flow: column;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.field-search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
