.container-details {
  width: 100%;
  display: flex;
}

.table-select-details {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  background-color: #ffffff;
}

.labels-headers {
  opacity: 0.61;
  font-family: Rubik;
  font-size: 12.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 8px;
}

.labels-headers:hover {
  cursor: pointer;
  color: #333333;
}

.labels-headers-marked {
  font-family: Rubik;
  font-size: 18.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  margin-top: 32px;
  margin-bottom: 32px;
  margin-right: 8px;
}

.labels-headers-marked:hover {
  cursor: pointer;
  color: #333333;
}

.select-item-header-course {
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 8px;
  margin-right: 100px;
  width: 150px;
}

td {
  font-family: Rubik;
  font-size: 13.7px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  padding: 0px 20px 0px;
  align-items: center;
}

.th-row-details {
  height: 40px;
  display: flex;
  align-items: center;
}

.avatar-user {
  width: 30px;
  align-items: center;
  margin-right: 10px;
}

.body-details-first {
  margin-left: 9px;
}

.th-progress {
  width: 450px;
  display: flex;
  position: relative;
}

.progress-bar {
  background-color: #e5156b;
}

.section-users-course {
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  margin: 10px 0px;
}

.header-list {
  margin: 0;
  padding: 0;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-body {
  background-color: transparent;
}

.th-name {
  width: 260px;
}

.th-name img {
  border-radius: 100%;
}

.th-email {
  width: 250px;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  font-family: Rubik;
  font-style: normal;
  padding-left: 34px;
}

.th-date-conclusion {
  width: 220px;
  padding-left: 60px;
}

.btn-certified {
  margin: 0;
  padding: 0;
  width: 200px;
  border: none;
  background-color: transparent;
}

.certificate-title-confirm {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 24px;
  color: #2f313d;
  font-weight: 600;
  margin-top: 10px;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  font-family: Poppins;
}

.certificate-btn-ok {
  margin: 30px 0px;
}

.th-certified {
  display: flex;
  justify-content: center;
}

.th-not-certified {
  display: flex;
  width: 412px;
  justify-content: center;
}
