.dialog-page {
  opacity: 0.57;
  filter: blur(10);
  background-color: #ffffff;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.body-dialog-table {
  width: 50%;
  height: 391px;
  border-radius: 4px;
  position: relative;
  z-index: 99000;
  top: 0;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.22);
  background-color: black;
  border: solid 1.2px #dcdcdc;
}

.display-dialog {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label-alert {
  font-family: Rubik;
  font-size: 22.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3d3737;
}

.Modal {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  width: 60%;
  height: 60%;
  z-index: 50000000;
}

.confirm-btn-resend-token {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
