.header-chart-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 50px;
}
.header-chart-publication-description {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.main-label-chart-publication {
  font-family: Rubik;
  font-size: 24.8px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333333;
}
.secondary-label-chart-publication {
  font-family: Rubik;
  font-size: 16.1px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
}
